import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: './tabs/tabs.module#TabsPageModule' },
  { path: 'conceptos', loadChildren: './pages/conceptos/conceptos.module#ConceptosPageModule' },
  { path: 'recomendaciones', loadChildren: './pages/recomendaciones/recomendaciones.module#RecomendacionesPageModule' },
  { path: 'info', loadChildren: './pages/info/info.module#InfoPageModule' },
  { path: 'producto/:name', loadChildren: './pages/producto/producto.module#ProductoPageModule' },
  { path: 'huella', loadChildren: './pages/huella/huella.module#HuellaPageModule' }

];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
